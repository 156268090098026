// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import loHas from 'lodash/has';

export const mergeValue = (current, value: unknown, path: string | Array<string>) => {
    const keys = typeof path === 'string' ? [path] : path;

    const doMerge = (test: unknown): boolean => {
        return typeof test === 'object' && !Array.isArray(test) && test !== null && loHas(current, path);
    };

    if (keys.length > 4 || keys.length < 1) {
        // ERROR not supporting > four levels or no keys
        return current;
    }

    if (keys.length > 3) {
        return {
            ...current,
            [keys[0]]: {
                ...(current && current[keys[0]]),
                [keys[1]]: {
                    ...(current && current[keys[0]] && current[keys[0]][keys[1]]),
                    [keys[2]]: {
                        ...(current &&
                            current[keys[0]] &&
                            current[keys[0]][keys[1]] &&
                            current[keys[0]][keys[1]][keys[2]]),
                        [keys[3]]: doMerge(value)
                            ? { ...current[keys[0]][keys[1]][keys[2]][keys[3]], ...value }
                            : value,
                    },
                },
            },
        };
    }

    if (keys.length > 2) {
        return {
            ...current,
            [keys[0]]: {
                ...(current && current[keys[0]]),
                [keys[1]]: {
                    ...(current && current[keys[0]] && current[keys[0]][keys[1]]),
                    [keys[2]]: doMerge(value) ? { ...current[keys[0]][keys[1]][keys[2]], ...value } : value,
                },
            },
        };
    }

    if (keys.length > 1) {
        return {
            ...current,
            [keys[0]]: {
                ...(current && current[keys[0]]),
                [keys[1]]: doMerge(value) ? { ...current[keys[0]][keys[1]], ...value } : value,
            },
        };
    }

    return {
        ...current,
        [keys[0]]: doMerge(value) ? { ...current[keys[0]], ...value } : value,
    };
};
